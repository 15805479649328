
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  props: 
  {
      id: { required: true }
  },
  name: "apps-product-hsn-details",
  components: {
  },
  methods: {
    
  },
  setup(props) {

    var ids = ref(props.id);
    const store = useStore();
    var tax_list: any[] = []
   interface ViewCategories {
       branch_name,
       gstin,
       gst_type,
       city_name,
       state_name,
       country_name,
       pincode,
       title,
       company_branch_id,
       address_line_1,
       jurisdiction,
       company_id,
      active : {
        label :string;
        color :string;
      }
    };    
    
    var resultsView = ref<ViewCategories>();
  
    resultsView.value = {
       branch_name: "",
       address_line_1: "",
       jurisdiction: "",
       gst_type: "",
       gstin: "",
       city_name: "",
       state_name: "",
       country_name : "",
       pincode : "",
       title: "",
       company_branch_id: "",
       company_id: "",
      active: {
        label: "",
        color: "",
      }
    }

    const getProductHsnDetails = async () => {
      try {

      var values = { 
      "company_id"  : "", 
      "company_branch_id" : props.id,
      "page"  : 1, 
      "records_per_page" : 1}

      await store.dispatch(Actions.CUST_GET_PLANT_LIST, values).then(({ data }) => {

        var status_label = ""
        var status_color = ""
        var active_label = ""
        var active_color = ""

        if (data.active){
            active_label = "Yes";
            active_color = "success";                  
        }else{
            active_label = "No";
            active_color = "danger";
        }
        
        for (var tax in data.tax_slab){
          if(data.tax_slab[tax] == 1){
            tax_list.push(tax)
          }
        }

        resultsView.value = {
          company_branch_id : data.company_branch_id,
          company_id : data.company_id,
              gstin : data.gstin,
              city_name : data.city_name,
              state_name : data.state_name,
              branch_name : data.branch_name,
              title : data.branch_title_type_name,
              gst_type : data.gst_type,
              address_line_1 : data.address_line_1,
              jurisdiction : data.jurisdiction,
              country_name : data.country_name,
              pincode :  data.pincode,
          active: {
            label: active_label,
            color: active_color
          }, 
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
        
      } catch (e) {
        console.log(e);
      }
    };        
    
    onMounted( async () => {
      await getProductHsnDetails();
      setCurrentPageBreadcrumbs("Plant Details", ["Entity Profile"]);
    });


    return {
      resultsView,
      ids,
    };

  }
});
